import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SettingSection } from 'src/app/modules/setting/settings.base';
import {
  AccountType,
  advertiserCompanyFormTypeOptions,
  companyFormTypeOptions,
  IAccountRequest,
  IAccountResponse,
  IAddress,
  publisherCompanyFormTypeOptions,
} from 'src/app/shared/constants/account';
import { UrlRegex } from 'src/app/shared/constants/url';
import { AccountService } from 'src/app/shared/services/account.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { DataService } from 'src/app/shared/services/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TitleService } from 'src/app/shared/services/title-service';

import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent
  extends SettingSection implements OnInit, OnDestroy {

  private currentAccountType;
  private currentAccountId;
  private getAccountSubscription: Subscription;
  private updateAccountSubscription: Subscription;
  private countryCodeSubscription: Subscription;
  private permissions = [];
  public accountData;
  public submitted = false;
  public countryCodeOptions;
  public countryDialCodeOptions;
  public companyTypeFormOptions;
  public companyFormTypeOptions;
  public isAccountCommercial = true;
  public accountSettingForm: UntypedFormGroup;
  public selectedCountryCode;

  public readOnly = false;

  breadCrumbData = [
    { name: 'Settings', link: '/setting/account' },
    { name: 'Account', link: '/setting/account' },
  ];

  createForm() {
    this.accountSettingForm = this.fb.group({
      accountId: [''],
      address: this.fb.group({
        streetNameAndNumber: ['', Validators.required],
        buildingNameAndNumber: [''],
        zipCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
      }),
      contact: this.fb.group({
        emailAddress: ['', [Validators.required, Validators.email]],
        websiteUrl: ['', [Validators.required, Validators.pattern(UrlRegex)]],
        phoneNumber: ['', [Validators.required]],
        countryCode: [''],
      }),
    });
  }

  constructor(
    public breadcrumbService: BreadcrumbService,
    public coreService: CoreService,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private permissionsService: NgxPermissionsService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
  ) {
    super();

    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.currentAccountType = this.coreService.editAccountType;
      this.currentAccountId = this.coreService.editUserAccountId;
    } else {
      this.currentAccountType = this.coreService.getCurrentAccountType();
      this.currentAccountId = this.coreService.getCurrentAccountId();
      this.permissions.push(this.currentAccountType);
      this.permissionsService.loadPermissions(this.permissions);
    }
  }

  get nextUrl() {
    return this.route.snapshot.queryParams.returnUrl;
  }

  addAccountInfoControls() {
    this.accountSettingForm.removeControl('accountInfo');
    let accountInfo;

    if (this.isAccountCommercial) {
      accountInfo = {
        accountType: ['Commercial', [Validators.required]],
        companyForm: ['', Validators.required],
        companyName: ['', Validators.required],
        companyId: [''],
        referral: [{ value: '', disabled: true }],
      };
    } else {
      accountInfo = {
        accountType: ['Private', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        referral: [{ value: '', disabled: true }],
      };
    }

    this.accountSettingForm.addControl(
      'accountInfo',
      this.fb.group(accountInfo),
    );

    if (this.readOnly) {
      this.accountSettingForm.disable();
    }
  }

  checkAccountType(data) {
    // Only for publisher role
    if (data.id === 'Private') {
      this.isAccountCommercial = false;
    } else {
      this.isAccountCommercial = true;
    }

    this.addAccountInfoControls();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Setting - Account Settings');
    this.companyFormTypeOptions = companyFormTypeOptions;
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);

    this.createForm();
    this.observeCountryCode();

    this.getAccountInfo();

    this.countryCodeOptions = this.dataService.countrySelectItems();
    this.countryDialCodeOptions = this.dataService.countryDialCodes();

    if (this.currentAccountType === AccountType.PUBLISHER) {
      this.companyTypeFormOptions = publisherCompanyFormTypeOptions;
    } else {
      this.companyTypeFormOptions = advertiserCompanyFormTypeOptions;
    }

    this.applyAccessScope();
  }

  async applyAccessScope() {
    await this.applyAccessScopeFlags();

    if (this.readOnly) {
      this.accountSettingForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeObject(this.getAccountSubscription);
    this.unsubscribeObject(this.updateAccountSubscription);
    this.unsubscribeObject(this.countryCodeSubscription);
  }

  get accountInfo() {
    return this.accountSettingForm.get('accountInfo');
  }

  get address() {
    return this.accountSettingForm.get('address');
  }

  addressGroupControl(controlName) {
    return this.accountSettingForm.get('address').get(controlName);
  }

  contactGroupControl(controlName) {
    return this.accountSettingForm.get('contact').get(controlName);
  }

  accountInfoGroupControl(controlName) {
    return this.accountSettingForm.get('accountInfo').get(controlName);
  }

  onSubmit() {
    this.submitted = true;

    if (this.accountSettingForm.invalid) {
      return;
    }

    this.updateAccount();
  }

  getAccountInfo() {
    const getAccount = this.currentAccountType === AccountType.PUBLISHER ?
      this.accountService.getPublisherAccount(this.currentAccountId) :
      this.accountService.getAdvertiserAccount(this.currentAccountId);

    this.getAccountSubscription = getAccount.subscribe((accountData: any) => {
      const account = accountData.account;

      const obj: any = {
        accountId: account.id,
        accountInfo: {
          accountType: account.business_type,
          companyForm: account.company_form,
          companyName: account.company_name,
          companyId: account.company_id,
        },
        address: {
          streetNameAndNumber: account.contact_address?.line1,
          buildingNameAndNumber: account.contact_address?.line2,
          zipCode: account.contact_address?.zip_code,
          city: account.contact_address?.city,
          country: account.contact_address?.country,
        },
        contact: {
          emailAddress: account.contact_email,
          websiteUrl: account.website_url,
          phoneNumber: account.contact_phone,
          countryCode: account.contact_phone_country_code,
        },
      };

      this.isAccountCommercial = (account.business_type !== 'Private');
      this.addAccountInfoControls();

      if (this.isAccountCommercial) {
        obj.accountInfo = {
          accountType: account.business_type,
          companyForm: account.company_form,
          companyName: account.company_name,
          companyId: account.company_id,
          referral: account.referral,
        };
      } else {
        obj.accountInfo = {
          accountType: account.business_type,
          firstName: account.first_name,
          lastName: account.last_name,
          referral: account.referral,
        };
      }

      this.accountSettingForm.patchValue(obj);
    }, (error: HttpErrorResponse) => {
      this.notificationService.showError(error?.message);
    });
  }

  updateAccount() {
    const data = this.formateData(this.accountSettingForm.value);

    const updateAccount = this.currentAccountType === AccountType.PUBLISHER ?
      this.accountService.updatePublisherAccount(this.currentAccountId, data) :
      this.accountService.updateAdvertiserAccount(this.currentAccountId, data);

    this.updateAccountSubscription = updateAccount.subscribe(
      (accountData: IAccountResponse) => {
        if (accountData) {
          this.notificationService.showSuccess('Account Setting Updated Successfully');
          if (this.nextUrl) {
            this.router.navigate([this.nextUrl]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
        this.coreService.showError('Account Setting Not Updated');
      },
    );
  }

  formateData(formData) {
    const accountData = {} as IAccountResponse;
    accountData.id = formData.accountId;
    accountData.account_type = this.currentAccountType;
    accountData.api_token = formData.apiToken;

    if (formData.accountInfo) {
      accountData.business_type = formData.accountInfo.accountType;
      accountData.first_name = formData.accountInfo.firstName;
      accountData.last_name = formData.accountInfo.lastName;
      accountData.company_name = formData.accountInfo.companyName;
      accountData.company_form = formData.accountInfo.companyForm;
      accountData.company_id = formData.accountInfo.companyId;
    }

    if (formData.address) {
      accountData.contact_address = {} as IAddress;
      accountData.contact_address.line1 = formData.address.streetNameAndNumber;
      accountData.contact_address.line2 = formData.address.buildingNameAndNumber;
      accountData.contact_address.zip_code = formData.address.zipCode;
      accountData.contact_address.city = formData.address.city;
      accountData.contact_address.country = formData.address.country;
    }

    if (formData.contact) {
      accountData.contact_email = formData.contact.emailAddress;
      accountData.website_url = formData.contact.websiteUrl;
      accountData.contact_phone = formData.contact.phoneNumber;
      accountData.contact_phone_country_code = formData.contact.countryCode;
    }

    const data = {} as IAccountRequest;
    data.account = accountData;
    return data;
  }

  unsubscribeObject(object: Subscription) {
    if (object) {
      object.unsubscribe();
    }
  }

  observeCountryCode() {
    const countryCodeControl = this.contactGroupControl('countryCode');
    const phoneNumberControl = this.contactGroupControl('phoneNumber');

    this.countryCodeSubscription = countryCodeControl.valueChanges.subscribe((value) => {
      if (value) {
        phoneNumberControl.enable();
      } else {
        phoneNumberControl.disable();
      }
    });
  }

  get isCurrentAccountAdmin() {
    return this.coreService.getCurrentAccountType() === AccountType.ADMIN;
  }
}
